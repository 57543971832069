import { lazy } from 'react';

const BookingReviews = lazy(() => import('./pages/BookingReviews'));
const CustomerReviews = lazy(() => import('./pages/CustomerReviews'));
const BookingDetails = lazy(() => import('./pages/BookingDetails'));
const BookingConfirm = lazy(() => import('./pages/BookingConfirm'));

const AboutUsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'customer/reviews',
      element: <CustomerReviews />,
    },
    {
      path: 'customer/booking-reviews',
      element: <BookingReviews />,
    },
    {
      path: 'customer/booking-details',
      element: <BookingDetails />,
    },
    {
      path: 'customer/confirm-booking',
      element: <BookingConfirm />,
    },
  ],
};

export default AboutUsConfig;
