import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import EnkSvgIcon from '@enk/core/EnkSvgIcon';
import { selectUser } from 'app/store/userSlice';

function UserMenu(props) {
  const user = useSelector(selectUser);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <div
      style={{
        marginRight: userMenu ? 0 : undefined, // Conditionally set margin-right
      }}
    >
      <Button
        className="min-h-40 min-w-40 px-0 md:pl-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user.data.displayName}
          </Typography>
        </div>

        {user?.data?.avatar ? (
          <Avatar
            lassName="md:mx-4"
            alt={user.data.displayName}
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${user?.data?.avatar}`}
          />
        ) : (
          <Avatar className="md:mx-4">{user?.data?.first_name[0]}</Avatar>
        )}
       
      </Button>

      <Popover
        disablePortal
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
        sx={{
          '& .MuiPopover-paper': {
            paddingRight: 0, // Or adjust as needed
            marginRight: 0, // If margin is also being added
          },
        }}
      >
        {user?.data?.id ? (
          <>
            <MenuItem
              component={NavLink}
              to="/profile"
              onClick={() => {
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <EnkSvgIcon>heroicons-solid:user</EnkSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            
            <MenuItem
              component={NavLink}
              to="/profile/service-history"
              onClick={() => {
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <EnkSvgIcon>heroicons-solid:document-report</EnkSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Booking History" />
            </MenuItem>

            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <EnkSvgIcon>heroicons-outline:logout</EnkSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        ) : (
          <MenuItem component={Link} to="/sign-in" role="button">
            <ListItemIcon className="min-w-40">
              <EnkSvgIcon>heroicons-outline:lock-closed</EnkSvgIcon>
            </ListItemIcon>
            <ListItemText primary="Sign In" />
          </MenuItem>
        )}
      </Popover>
    </div>
  );
}

export default UserMenu;
