import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { LoadingButton } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Turnstile, { useTurnstile } from 'react-turnstile';
import settingsConfig from 'src/app/configs/settingsConfig';
import * as yup from 'yup';
import _ from '@lodash';
import FormHelperText from '@mui/material/FormHelperText';
import EnkUtils from '@enk/utils/EnkUtils';
import { showMessage } from 'app/store/enk/messageSlice';
import CountryCodeSelectorFlag from 'app/shared-components/CountryCodeSelectorFlag';
import { Button } from '@mui/material';
import { registerUser, verifyUser, setUser } from '../../store/userSlice';
import jwtService from '../../auth/services/jwtService';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-adornedEnd': {
    paddingRight: 8,
  },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  first_name: yup.string().required('You must enter display name'),
  last_name: yup.string(),
  email: yup.string().email('You must enter a valid email'),
  mobile: yup.string(),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  acceptTermsConditions: yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
  country_code: yup.string().default('us'),
});

const verificationSchema = yup.object().shape({
  email_code: yup.string().nullable(),
  mobile_code: yup.string().nullable(),
});

const defaultValues = {
  first_name: '',
  last_name: '',
  mobile: '',
  email: '',
  password: '',
  acceptTermsConditions: false,
  country_code: 'us',
};

const defaultVerification = {
  email_code: '',
  mobile_code: '',
};

const countries = EnkUtils.getCountryListWithCallingCode();

function SignUpForm({ onSignInLinkClickCallback, handleCloseAuthDialog, ...props }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [waitAuthCheck, setWaitAuthCheck] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifyMobile, setVerifyMobile] = useState(false);
  const [token, setToken] = useState(null);
  const turnstile = useTurnstile();

  const { control, formState, handleSubmit, reset, setError, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const selectedCountries = ['us', 'in', 'ca'];

  const {
    control: vControl,
    formState: vFormState,
    handleSubmit: vHandleSubmit,
    setError: vSetError,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(verificationSchema),
    defaultValues: defaultVerification,
  });

  const { isValid: vIsValid, dirtyFields: vDirtyFields, errors: vErrors } = vFormState;

  const { isValid, dirtyFields, errors } = formState;

  function getMobileNumber() {
    const mobile = getValues('mobile');
    const country = _.find(countries, { iso: getValues('country_code') });
    if (country && mobile && !mobile.includes('+')) {
      return EnkUtils.sanitizeMobile(`${country.code}${mobile}`);
    }
    return EnkUtils.sanitizeMobile(mobile);
  }

  function onSubmit(data) {
    setWaitAuthCheck(true);
    if (!data.email && !data.mobile) {
      setError('password', {
        type: 'manual',
        message: 'Please enter a valid Email address or Phone number to continue',
      });
      return;
    }
    if (data.email && !EnkUtils.validateEmail(data.email)) {
      setError('email', {
        type: 'manual',
        message: 'You must enter a valid Email address to continue',
      });
      return;
    }
    data.mobile = getMobileNumber();
    if (data.mobile && !EnkUtils.validateMobile(data.mobile)) {
      setError('mobile', {
        type: 'manual',
        message: 'You must enter a valid Phone number to continue',
      });
      return;
    }
    dispatch(registerUser(data)).then(({ error, payload }) => {
      console.log(error, payload, 'register user response');
      if (!error) {
        setWaitAuthCheck(false);
        initVerification(payload);
      } else {
        setWaitAuthCheck(false);
        setError('password', {
          type: 'manual',
          message: error.message,
        });
      }

      setToken(null);
      turnstile.reset();
    });
  }

  function initVerification(data) {
    console.log(data, 'init verification');
    setVerifyEmail(data.verify_email || false);
    setVerifyMobile(data.verify_mobile || false);
    if (data.verify_email || data.verify_mobile) {
      setShowVerification(true);
    } else {
      const isEmailValid = EnkUtils.validateEmail(getValues('email'));
      const isMobileValid = EnkUtils.validateMobile(getMobileNumber());
      setError(!isEmailValid ? 'email' : 'password', {
        type: 'manual',
        message: !isEmailValid
          ? 'You must enter a valid email address'
          : 'Error registering account. Please try again later.',
      });
      setError(!isMobileValid ? 'mobile' : 'password', {
        type: 'manual',
        message: !isMobileValid
          ? 'You must enter a valid phone number'
          : 'Error registering account. Please try again later.',
      });
    }
  }

  function handleJwtSignIn(user) {
    // eslint-disable-next-line camelcase
    dispatch(setUser(user));
    if (handleCloseAuthDialog) handleCloseAuthDialog();
  }
  function onSubmitVerification(data) {
    console.log(data, 'submit verification');
    if (verifyEmail && !data.email_code && verifyMobile && !data.mobile_code) {
      vSetError('email_code', {
        type: 'manual',
        message: 'Email verification code is required',
      });
      vSetError('mobile_code', {
        type: 'manual',
        message: 'Phone number verification code is required',
      });
      return;
    }
    if (verifyEmail && !data.email_code && !verifyMobile) {
      vSetError('email_code', {
        type: 'manual',
        message: 'Email verification code is required',
      });
      return;
    }
    if (verifyMobile && !data.mobile_code && !verifyEmail) {
      vSetError('mobile_code', {
        type: 'manual',
        message: 'Phone number verification code is required',
      });
      return;
    }
    const body = {
      email: getValues('email'),
      mobile: getMobileNumber(),
      ...data,
    };
    setWaitAuthCheck(true);
    dispatch(verifyUser(body)).then(({ error, payload }) => {
      console.log(error, payload, 'verify response');
      if (!error && payload.username) {
        jwtService
          .signInWithUserNameAndPassword(payload.username, getValues('password'), token)
          .then((response) => {
            handleJwtSignIn(response);
            setToken(null);

          })
          .catch((err) => {
            setWaitAuthCheck(false);
            dispatch(showMessage({ message: err.message || 'Error registering user' }));
            setError('root', {
              type: 'manual',
              message: err.message,
            });
          });
      } else {
        setWaitAuthCheck(false);
        dispatch(showMessage({ message: error.message || 'Error verifying account.' }));
        vSetError('root', {
          type: 'manual',
          message: error.message || 'Error verifying account. Please try again later.',
        });
      }
    });
  }

  function handleSignInLinkClick() {
    if (onSignInLinkClickCallback) {
      onSignInLinkClickCallback();
    } else {
      // navigate('/sign-in');
      navigate('/');
    }
  }

  return (
    <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0" {...props}>
      <img className="w-48" src="assets/images/logo/logo.svg" alt="logo" />

      <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
        Sign up
      </Typography>
      <div className="flex items-baseline mt-2 font-medium">
        <Typography>Already have an account?</Typography>
        <Button className="ml-4" color="secondary" disableElevation onClick={handleSignInLinkClick}>
          Sign in here.
        </Button>
      </div>
      {showVerification ? (
        <form
          name="verificationForm"
          noValidate
          key={2}
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={vHandleSubmit(onSubmitVerification)}
        >
          {verifyEmail && (
            <Controller
              name="email_code"
              control={vControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email Code"
                  error={!!vErrors.email_code}
                  helperText={vErrors?.email_code?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}
          {verifyMobile && (
            <Controller
              name="mobile_code"
              control={vControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Phone Number Code"
                  error={!!vErrors.mobile_code}
                  helperText={vErrors?.mobile_code?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}

          <LoadingButton
            variant="contained"
            color="secondary"
            className=" w-full mt-16 rounded-8"
            aria-label="Verify Account"
            loading={waitAuthCheck}
            disabled={_.isEmpty(vDirtyFields) || !vIsValid}
            type="submit"
            size="large"
          >
            <span>Verify Account</span>
          </LoadingButton>
        </form>
      ) : (
        <form
          name="registerForm"
          noValidate
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="First name"
                autoFocus
                error={!!errors.first_name}
                helperText={errors?.first_name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Last name"
                error={!!errors.last_name}
                helperText={errors?.last_name?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Email"
                type="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="mobile"
            control={control}
            render={({ field }) => (
              <CustomTextField
                {...field}
                label="Mobile"
                placeholder="Mobile"
                variant="outlined"
                className="mb-24"
                fullWidth
                error={!!errors.mobile}
                helperText={errors?.mobile?.message}
                InputProps={{
                  startAdornment: (
                    <Controller
                      control={control}
                      name="country_code"
                      render={({ field: _field }) => (
                        <InputAdornment position="start">
                          <CountryCodeSelectorFlag
                            selectedCountries={selectedCountries}
                            {..._field}
                          />
                        </InputAdornment>
                      )}
                    />
                  ),
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Password"
                type="password"
                error={!!errors.password}
                helperText={errors?.password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Turnstile
            className="mt-8 w-full"
            theme={theme.palette.mode || 'light'}
            sitekey={settingsConfig.turnstileWidgetsKey}
            onVerify={(t) => {
              setToken(t);
              console.log('token', t);
            }}
            onExpire={() => {
              setToken(null);
              turnstile.reset();
            }}
            size="flexible"
          />

          <Controller
            name="acceptTermsConditions"
            control={control}
            render={({ field }) => (
              <FormControl className="items-left" error={!!errors.acceptTermsConditions}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(ev, ch) => field.onChange(ch)}
                    />
                  }
                  label={
                    <Typography>
                      By creating an account, you agree to our{' '}
                      <Link to="/terms" target="_blank">
                        Terms & Conditions
                      </Link>
                    </Typography>
                  }
                />
                <FormHelperText>{errors?.acceptTermsConditions?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <LoadingButton
            variant="contained"
            color="secondary"
            className="w-full mt-24 rounded-8"
            aria-label="Register"
            loading={waitAuthCheck}
            disabled={_.isEmpty(dirtyFields) || !isValid || !token}
            type="submit"
            size="large"
          >
            Proceed
          </LoadingButton>
        </form>
      )}
    </div>
  );
}

export default SignUpForm;
