/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import settingsConfig from 'app/configs/settingsConfig';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from './cookieSlice';

let clientId = '';

export function getOrCreateClientId() {
  clientId = getCookie('x-client-id');

  if (!clientId) {
    // Create a new UUID if not found in cookie
    clientId = uuidv4();
    setCookie('x-client-id', clientId, 60); // Expires in 30 minutes
  }

  axios.defaults.headers.common['x-client-id'] = clientId;

  return clientId;
}

export function getOrSetTab(t) {
  if (t) {
    setCookie('provider-tab', t, 60);
    return t;
  }
  let tab = getCookie('provider-tab');

  if (!tab) {
    tab = '1';
    setCookie('provider-tab', tab, 60);
  }

  return tab;
}

export function refreshClientId() {
  clientId = uuidv4();
  setCookie('x-client-id', clientId, 60);
  axios.defaults.headers.common['x-client-id'] = clientId;

  return clientId;
}

getOrCreateClientId();

export const getSalonWebsiteTemplate = createAsyncThunk(
  'salon/getSalonWebsiteTemplate',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/template`, options);
    if (!response.data.success) {
      throw new Error('Failed to get salon data');
    }
    return {
      id: options.salon_id,
      calendar_schedule_exceptions: response.data.calendar_schedule_exceptions,
      calendar_schedules: response.data.calendar_schedules,
      calendar_template: response.data.calendar_template,
      settings: response.data.settings,
      salon: response.data.salon,
    };
  }
);

export const getSalonWebsiteServices = createAsyncThunk(
  'salon/getSalonWebsiteServices',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/services`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get salon services');
    }
    return {
      id: options.salon_id,
      services: response.data.items,
      options,
    };
  }
);

export const getSalonFromSlug = createAsyncThunk('salon/getSalonFromSlug', async (options) => {
  const response = await axios.get(`${settingsConfig.apiURL}/salon/slug/${options.slug}`);
  if (!response.data.success || !response.data.item) {
    throw new Error('Failed to get salon ID');
  }
  return response.data.item;
});

export const getSalonIndevidualServices = createAsyncThunk(
  'salon/getSalonIndevidualServices',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/services`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get salon services');
    }
    return {
      id: options.salon_id,
      services: response.data.items,
      options,
    };
  }
);

export const getSalonWebsiteEmployees = createAsyncThunk(
  'salon/getSalonWebsiteEmployees',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/employees`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get salon employees');
    }
    return {
      id: options.salon_id,
      employees: response.data.items,
      options,
    };
  }
);

export const searchSalonAddresses = createAsyncThunk(
  'salon/searchSalonAddresses',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/address/list`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get addresses');
    }
    return response.data;
  }
);

export const searchSalonList = createAsyncThunk('salon/searchSalonList', async (options) => {
  const response = await axios.post(`${settingsConfig.apiURL}/salon/search`, options);
  if (!response.data.success || !response.data.items) {
    throw new Error('Failed to get salons');
  }
  return response.data;
});

export const getServiceSlots = createAsyncThunk('salon/getServiceSlots', async (options) => {
  const response = await axios.post(
    `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/schedule/slots-new`,
    options,
    {
      headers: {
        'X-timezone': options.timezone || 'UTC',
      },
    }
  );
  if (!response.data.success || !response.data.items) {
    throw new Error('Failed to get slot list');
  }
  return { items: response.data.items, options };
});

export const getServiceInCustomer = createAsyncThunk(
  'salon/getServiceInCustomer',
  async (salonId) => {
    const response = await axios.get(
      `${settingsConfig.apiURL}/booking/salon/${salonId}/client/all`
    );
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get slot list');
    }
    return { items: response.data.items };
  }
);

export const saveServiceInCustomer = createAsyncThunk(
  'salon/SaveServiceInCustomer',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/client/add`,
      options
    );
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get slot list');
    }
    return { items: response.data.items };
  }
);

export const deleteServiceInCustomer = createAsyncThunk(
  'salon/deleteServiceInCustomer',
  async (options) => {
    const response = await axios.delete(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/client/delete/${options.id}`,
      options
    );
    if (!response.data.success) {
      throw new Error('Failed to delete booking slot');
    }
    return { success: response.data.success };
  }
);

export const deleteAllServiceInCustomer = createAsyncThunk(
  'salon/deleteAllServiceInCustomer',
  async (options) => {
    const response = await axios.delete(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/client/all`,
      options
    );
    if (!response.data.success) {
      throw new Error('Failed to delete booking slots');
    }
    return { success: response.data.success };
  }
);

export const deleteCustomerServiceInCustomer = createAsyncThunk(
  'salon/deleteCustomerServiceInCustomer',
  async (options) => {
    const response = await axios.delete(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/client/customer/${options.num}`,
      options
    );
    if (!response.data.success) {
      throw new Error('Failed to delete booking slots');
    }
    return { success: response.data.success };
  }
);

export const getProductsInServices = createAsyncThunk(
  'salon/getProductsInServices',
  async (options) => {
    const response = await axios.post(`${settingsConfig.apiURL}/salon/website/products`, options);
    if (!response.data.success || !response.data.items) {
      throw new Error('Failed to get product list');
    }
    return { items: response.data.items, options };
  }
);

export const createOnlineBooking = createAsyncThunk(
  'salon/createOnlineBooking',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/online/create`,
      options
    );
    if (!response.data.success || !response.data.item) {
      throw new Error(response.data.message ? response.data.message : 'Failed to create booking');
    }
    return response.data;
  }
);

export const createGuestOnlineBooking = createAsyncThunk(
  'salon/createGuestOnlineBooking',
  async (options) => {
    const response = await axios.post(
      `${settingsConfig.apiURL}/booking/salon/${options.salon_id}/online/create/guest`,
      options
    );
    if (!response.data.success || !response.data.item) {
      throw new Error(response.data.message ? response.data.message : 'Failed to create booking');
    }
    return response.data;
  }
);

const salonAdapter = createEntityAdapter({});

export const { selectAll: selectSalonList, selectById: selectSalonById } =
  salonAdapter.getSelectors((state) => state.salon);

const salonSlice = createSlice({
  name: 'salon',

  initialState: salonAdapter.getInitialState({
    searchText: '',
    totalItems: 0,
    addresses: [],
    salons: [],
    /**
     * [{ service_id, salon_id, date, slots: [] }]
     */
    serviceSlots: {},
    serviceEmployees: {},
    employeeServices: {},
    bookingServices: [],
    empSevices: [],
  }),

  reducers: {
    setSalonSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSalonWebsiteTemplate.fulfilled, (state, action) =>
      salonAdapter.upsertOne(state, cloneDeep(action.payload))
    );

    builder.addCase(getSalonWebsiteServices.fulfilled, (state, action) => {
      salonAdapter.upsertOne(
        state,
        cloneDeep({ id: action.payload.id, services: action.payload.services })
      );
      if (action.payload.options.employee_id) {
        state.employeeServices[action.payload.options.employee_id] = cloneDeep(
          action.payload.services
        );
      }
    });

    builder.addCase(getSalonWebsiteEmployees.fulfilled, (state, action) => {
      salonAdapter.upsertOne(
        state,
        cloneDeep({ id: action.payload.id, employees: action.payload.employees })
      );
      if (action.payload.options.service_id) {
        state.serviceEmployees[action.payload.options.service_id] = cloneDeep(
          action.payload.employees
        );
      }
    });

    builder.addCase(searchSalonAddresses.fulfilled, (state, action) => {
      state.addresses = cloneDeep(action.payload.items);
    });

    builder.addCase(getSalonIndevidualServices.fulfilled, (state, action) => {
      state.empSevices = cloneDeep(action.payload.services);
    });

    builder.addCase(searchSalonList.fulfilled, (state, action) => {
      state.salons = cloneDeep(action.payload.items);
    });

    builder.addCase(getServiceInCustomer.fulfilled, (state, action) => {
      state.bookingServices = cloneDeep(action.payload.items);
    });

    builder.addCase(deleteAllServiceInCustomer.fulfilled, (state, action) => {
      state.bookingServices = [];
    });

    builder.addCase(getServiceSlots.fulfilled, (state, action) => {
      state.serviceSlots = cloneDeep(action.payload.items);
    });
  },
});

export const { setSalonSearchText } = salonSlice.actions;

export const selectSalonSearchText = ({ salon }) => salon.searchText;
export const selectTotalSalonHistory = ({ salon }) => salon.totalItems;
export const selectSalonAddressList = ({ salon }) => salon.addresses;
export const selectSalonSearchList = ({ salon }) => salon.salons;
export const selectServiceSlotList = ({ salon }) => salon.serviceSlots;
export const selectServiceEmployees = ({ salon }) => salon.serviceEmployees;
export const selectEmployeeServices = ({ salon }) => salon.employeeServices;
export const selectBookingServices = ({ salon }) => salon.bookingServices || [];
export const selectEmpSevices = ({ salon }) => salon.empSevices;

export default salonSlice.reducer;
