import { yupResolver } from '@hookform/resolvers/yup';
import { styled, useTheme } from '@mui/material/styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { Box, Button, Checkbox, FormControlLabel, Grid, Tab } from '@mui/material';
import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import EnkUtils from '@enk/utils/EnkUtils';
import { Link, useNavigate } from 'react-router-dom';
import { showMessage } from 'app/store/enk/messageSlice';
import CountryCodeSelectorFlag from 'app/shared-components/CountryCodeSelectorFlag';
import { useDispatch } from 'react-redux';
import Turnstile, { useTurnstile } from 'react-turnstile';
import EnkSvgIcon from '@enk/core/EnkSvgIcon';
import settingsConfig from 'src/app/configs/settingsConfig';
import jwtService from '../../auth/services/jwtService';
import {
  resetUserAccountInfo,
  validateUser,
  verifyUser,
  forgotPasswordComplete,
  setUser,
} from '../../store/userSlice';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-adornedEnd': {
    paddingRight: 8,
  },
  '& .MuiInputBase-input': {
    paddingRight: 8,
    paddingLeft: 8,
  },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - must be at least 8 chars.'),
});

const mSchema = yup.object().shape({
  mobile: yup.string().required('You must enter a phone number'),
  country_code: yup.string().default('us'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - must be at least 8 chars.'),
});

const resetSchema = yup.object().shape({
  email: yup.lazy((value, context) => {
    const { mobile } = context.parent;
    return !mobile || mobile.length === 0
      ? yup
          .string()
          .email('You must enter a valid email')
          .required('You must enter either an email or a mobile number')
      : yup.string().nullable().email('You must enter a valid email');
  }),

  mobile: yup.lazy((value, context) => {
    const { email } = context.parent;
    return !email || email.length === 0
      ? yup.string().required('You must enter either an email or a mobile number')
      : yup.string().nullable();
  }),
});

const defaultValues = {
  email: '',
  password: '',
  remember: true,
};

const defaultResetValues = {
  email: '',
  mobile: '',
  remember: true,
};

const mDefaultValues = {
  mobile: '',
  country_code: 'us',
  password: '',
};

const verificationSchema = yup.object().shape({
  email_code: yup.string().nullable(),
  mobile_code: yup.string().nullable(),
});

const fSchema = yup.object().shape({
  email_code: yup.string().nullable(),
  mobile_code: yup.string().nullable(),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - must be at least 8 chars.'),
});

const guestSchema = yup.object().shape({
  customer_first_name: yup.string().required('You must enter first name'),
  customer_last_name: yup.string().required('You must enter last name'),
  country_code: yup.string().default('us'),
  customer_mobile: yup.string().required('You must enter mobile no.'),
  customer_email: yup.string().email('Please enter a valid email').nullable(),
  acceptTermsConditions: yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
});

const defaultVerification = {
  email_code: '',
  mobile_code: '',
};

const fDefaultValues = {
  email_code: '',
  mobile_code: '',
  password: '',
};

const guestDefaultValues = {
  customer_first_name: '',
  customer_last_name: '',
  country_code: 'us',
  customer_mobile: '',
  customer_email: '',
  acceptTermsConditions: false,
};

const countries = EnkUtils.getCountryListWithCallingCode();

function SignInForm({
  allowGuestSignIn,
  onGuestSubmit,
  onSignUpLinkClickCallback,
  handleCloseAuthDialog,
  isBooking,
  setIsSignedIn,
  ...props
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [waitAuthCheck, setWaitAuthCheck] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showGuest, setShowGuest] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifyMobile, setVerifyMobile] = useState(false);
  const [forgotEmail, setForgotEmail] = useState(false);
  const [forgotMobile, setForgotMobile] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [token, setToken] = useState(null);
  const turnstile = useTurnstile();

  const { control, formState, handleSubmit, setError, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    control: resetFormControl,
    formState: resetFormState,
    handleSubmit: resetFormHandleSubmit,
    setError: resetFormSetError,
    setValue: resetFormSetValue,
  } = useForm({
    mode: 'onChange',
    defaultResetValues,
    resolver: yupResolver(resetSchema),
  });
  const {
    control: mControl,
    formState: mFormState,
    handleSubmit: mHandleSubmit,
    setError: mSetError,
    getValues: mGetValues,
    setValue: mSetValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: mDefaultValues,
    resolver: yupResolver(mSchema),
  });

  const {
    control: fControl,
    formState: fFormState,
    handleSubmit: fHandleSubmit,
    setError: fSetError,
    getValues: fGetValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: fDefaultValues,
    resolver: yupResolver(fSchema),
  });

  const {
    control: vControl,
    formState: vFormState,
    handleSubmit: vHandleSubmit,
    setError: vSetError,
    getValues: vGetValues,
    setValue: vSetValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(verificationSchema),
    defaultValues: defaultVerification,
  });
  const guestForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(guestSchema),
    defaultValues: guestDefaultValues,
  });
  guestForm.watch();

  const [useMobielreset, setUseMobileReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [processingReset, setProcessingReset] = useState(false);

  const { isValid: vIsValid, dirtyFields: vDirtyFields, errors: vErrors } = vFormState;
  const { isValid: fIsValid, dirtyFields: fDirtyFields, errors: fErrors } = fFormState;
  const { isValid, dirtyFields, errors } = formState;
  const { isValid: mIsValid, dirtyFields: mDirtyFields, errors: mErrors } = mFormState;
  const {
    isValid: resetFormIsValid,
    dirtyFields: resetFormDirtyFields,
    errors: resetFormErrors,
  } = resetFormState;

  console.log(resetFormErrors, 'resetFormErrors');
  console.log(resetFormIsValid, 'resetFormIsValid');
  console.log(resetFormDirtyFields, 'resetFormDirtyFields');
  console.log(resetFormState, 'resetFormState');

  const selectedCountries = ['us', 'in', 'ca', 'eg'];

  function initVerification(data) {
    console.log(data, 'init verification');
    setVerifyEmail(data.verify_email || false);
    setVerifyMobile(data.verify_mobile || false);
    if (data.verify_email || data.verify_mobile) {
      setShowVerification(true);
    }
  }

  function onSubmitVerification(data) {
    console.log(data, 'submit verification');
    if (verifyEmail && !data.email_code) {
      vSetError('email_code', {
        type: 'manual',
        message: 'Email verification code is required',
      });
      return;
    }
    if (verifyMobile && !data.mobile_code) {
      vSetError('mobile_code', {
        type: 'manual',
        message: 'Phone number verification code is required',
      });
      return;
    }
    const body = {};
    if (verifyEmail) {
      body.email = getValues('email');
      body.email_code = data.email_code;
    }
    if (verifyMobile) {
      body.mobile = getMobileNumber();
      body.mobile_code = data.mobile_code;
    }
    setWaitAuthCheck(true);
    dispatch(verifyUser(body)).then(({ error, payload }) => {
      console.log(error, payload, 'verify response');
      if (!error && payload.username) {
        if (verifyEmail) {
          onSubmit({ email: payload.username, password: getValues('password') });
        } else if (verifyMobile) {
          onMSubmit({ mobile: payload.username, password: mGetValues('password') }, false);
        }
      } else {
        setWaitAuthCheck(false);
        dispatch(showMessage({ message: error.message || 'Error verifying account.' }));
        vSetError('root', {
          type: 'manual',
          message: error.message || 'Error verifying account. Please try again later.',
        });
      }
    });
  }

  function getMobileNumber() {
    const mobile = mGetValues('mobile');
    const country = _.find(countries, { iso: mGetValues('country_code') });
    console.log(EnkUtils.sanitizeMobile(`${country.code}${mobile}`), `${country.code}${mobile}`);
    if (country && mobile && !mobile.includes('+')) {
      return EnkUtils.sanitizeMobile(`${country.code}${mobile}`);
    }
    return EnkUtils.sanitizeMobile(mobile);
  }

  function getGuestMobileNumber() {
    const mobile = guestForm.getValues('customer_mobile');
    const country = _.find(countries, { iso: guestForm.getValues('country_code') });
    console.log(EnkUtils.sanitizeMobile(`${country.code}${mobile}`), `${country.code}${mobile}`);
    if (country && mobile && !mobile.includes('+')) {
      return EnkUtils.sanitizeMobile(`${country.code}${mobile}`);
    }
    return EnkUtils.sanitizeMobile(mobile);
  }

  function handleJwtSignIn(user) {
    dispatch(setUser(user));
   
    if (isBooking) {
         setIsSignedIn(true);
    } else {
      navigate('/profile/service-history');
    }

    if (handleCloseAuthDialog) handleCloseAuthDialog();
  }

  function initForgotPassword() {
    const body = {};
    if (tabValue === '1') {
      const email = getValues('email');
      if (!email) {
        setError('email', {
          type: 'manual',
          message: 'You must enter a valid email address to reset your password',
        });
        return;
      }
      setForgotEmail(true);
      body.email = email;
    } else if (tabValue === '2') {
      const mobile = getMobileNumber();
      if (!mobile) {
        mSetError('mobile', {
          type: 'manual',
          message: 'You must enter a valid phone number to reset your password',
        });
        return;
      }
      body.mobile = mobile;
    }
    setShowForgotPassword(true);
  }

  function submitForgotPassword(data) {
    const body = {
      password: data.password,
    };
    if (forgotEmail) {
      if (!data.email_code) {
        fSetError('password', {
          type: 'manual',
          message: 'Error resetting password. Please try again later.',
        });
        return;
      }
      body.email = getValues('email');
      body.email_code = data.email_code;
    } else if (forgotMobile) {
      if (!data.mobile_code) {
        fSetError('password', {
          type: 'manual',
          message: 'Error resetting password. Please try again later.',
        });
        return;
      }
      body.mobile = getMobileNumber();
      body.mobile_code = data.mobile_code;
    }
    setWaitAuthCheck(true);
    dispatch(forgotPasswordComplete(body))
      .then(({ error, payload }) => {
        setWaitAuthCheck(false);
        if (!error && payload.success && payload.username) {
          dispatch(
            showMessage({ message: 'Password reset successfully. Logging in to your account now' })
          );
          initSignIn(
            { username: payload.username, password: data.password },
            tabValue === '1' ? 'email' : 'mobile',
            tabValue === '1' ? setError : mSetError
          );
        } else {
          fSetError('password', {
            type: 'manual',
            message: error?.message || 'Error resetting password. Please try again later.',
          });
        }
      })
      .catch((error) => {
        setWaitAuthCheck(false);
        fSetError('password', {
          type: 'manual',
          message: error?.message || 'Error resetting password. Please try again later.',
        });
      });
  }

  function initSignIn(options, type, showError) {
    setWaitAuthCheck(true);
    console.log('initSignIn token', token);
    try {
      jwtService
        .signInWithEmailAndPassword(options.email, options.password, token)
        .then((response) => {
          // No need to do anything, user data will be set at app/auth/AuthContext
          setWaitAuthCheck(false);
          handleJwtSignIn(response);
        })
        .catch((error) => {
          console.log(error);
          setWaitAuthCheck(false);
          const data = {};
          data.email = options.email;

          // validateIsUnverifiedUser(data);
          showError('password', {
            type: 'manual',
            message: error.message || 'Error signing in. Please reload the page and try again.',
          });
        })
        .finally(() => {
          setToken(null);
          turnstile.reset();
        });
    } catch (error) {
      showError('password', {
        type: 'manual',
        message: error.message || 'Error signing in. Please reload the page and try again.',
      });
    }
  }

  function initSignInM(options, type, showError) {
    setWaitAuthCheck(true);
    try {
      jwtService
        .signInWithPhoneAndPassword(options.mobile, options.password, token)
        .then((response) => {
          // No need to do anything, user data will be set at app/auth/AuthContext
          setWaitAuthCheck(false);
          handleJwtSignIn(response);
          setToken(null);
        })
        .catch((error) => {
          console.log(error);
          setWaitAuthCheck(false);
          const data = {};
          data.mobile = options.mobile;
          // validateIsUnverifiedUser(data);
          showError('password', {
            type: 'manual',
            message: error.message || 'Error signing in. Please reload the page and try again.',
          }).finally(() => {
            setToken(null);
            turnstile.reset();
          });
        });
    } catch (error) {
      showError('password', {
        type: 'manual',
        message: error.message || 'Error signing in. Please reload the page and try again.',
      });
    }
  }

  function onSubmit({ email, password }) {
    console.log(email, password, token);
    initSignIn({ email, password, token }, 'email', setError);
  }

  function validateIsUnverifiedUser(options) {
    setWaitAuthCheck(true);
    dispatch(validateUser(options)).then(({ error, payload }) => {
      setWaitAuthCheck(false);
      if (!error && payload.success) {
        initVerification(payload);
      } else if (options.email) {
        setError('password', {
          type: 'manual',
          message: 'User not found.',
        });
      } else if (options.mobile) {
        mSetError('password', {
          type: 'manual',
          message: 'User not found.',
        });
      }
    });
  }

  function onMSubmit(data, override = true) {
    const mobile = override ? getMobileNumber() : data.mobile;
    if (override && mobile && !EnkUtils.validateMobile(mobile)) {
      mSetError('mobile', {
        type: 'manual',
        message: 'You must enter a valid Phone number to continue',
      });
      return;
    }
    initSignInM({ mobile, password: data.password, token }, 'mobile', mSetError);
  }

  function onResetAccount(options) {
    setWaitAuthCheck(true);
    dispatch(resetUserAccountInfo(options))
      .then(({ error, payload }) => {
        console.log(payload);
        setWaitAuthCheck(false);
        if (!error && payload && payload.username) {
          onSubmit({ email: payload.username, password: options.password, token });
        } else {
          setError('password', {
            type: 'manual',
            message: 'Error resetting account. Please contact the administrator.',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setWaitAuthCheck(false);
        if (error?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setResetPassword(true);
        } else {
          setError('password', {
            type: 'manual',
            message: error.message,
          });
        }
      });
  }

  function onResetSubmit({ email, mobile }) {
    setProcessingReset(true);
    jwtService
      .requstNewPassword(email, mobile, token)
      .then((data) => {
        if (data.success) {
          dispatch(
            showMessage({
              message: 'Password reset link sent successfully',
              variant: 'success',
              autoHideDuration: 10000,
            })
          );
          setResetPassword(false);
        } else {
          dispatch(
            showMessage({
              message: data.message || 'Error sending password reset link',
              variant: 'error',
              autoHideDuration: 10000,
            })
          );
        }

        setToken(null);

        setProcessingReset(false);
      })
      .catch((error) => {
        setProcessingReset(false);
        resetFormSetError('password', {
          type: 'manual',
          message: error.message,
        });
      })
      .finally(() => {
        setToken(null);
        turnstile.reset();
      });
  }

  function onSubmitGuest(data) {
    console.log(data);
    if (onGuestSubmit) {
      onGuestSubmit(data);
    }
  }

  function getFormTitle() {
    if (showVerification) {
      return (
        <Typography className="mt-32 text-2xl font-extrabold tracking-tight leading-tight">
          Verify Account
        </Typography>
      );
    }
    if (resetPassword) {
      return (
        <Typography className="mt-32 text-2xl font-extrabold tracking-tight leading-tight">
          Forgot Password
        </Typography>
      );
    }
    if (showForgotPassword) {
      return (
        <Typography className="mt-32 text-2xl font-extrabold tracking-tight leading-tight">
          Forgot Password
        </Typography>
      );
    }
    if (showGuest) {
      return (
        <Typography className="mt-32 text-2xl font-extrabold tracking-tight leading-tight">
          Checkout with
        </Typography>
      );
    }
    return (
      <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
        Sign in with
      </Typography>
    );
  }

  function getFormContent() {
    if (showVerification) {
      return (
        <form
          name="verificationForm"
          noValidate
          key={4}
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={vHandleSubmit(onSubmitVerification)}
        >
          {verifyEmail && (
            <Controller
              name="email_code"
              control={vControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email Code"
                  error={!!vErrors.email_code}
                  helperText={vErrors?.email_code?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}
          {verifyMobile && (
            <Controller
              name="mobile_code"
              control={vControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Phone Number Code"
                  error={!!vErrors.mobile_code}
                  helperText={vErrors?.mobile_code?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}

          <Turnstile
            className="mt-8 w-full"
            theme={theme.palette.mode || 'light'}
            sitekey={settingsConfig.turnstileWidgetsKey}
            onVerify={(t) => {
              setToken(t);
              console.log('token', t);
            }}
            onExpire={() => {
              setToken(null);
              turnstile.reset();
            }}
            size="flexible"
          />

          <LoadingButton
            variant="contained"
            color="secondary"
            className=" w-full mt-16 rounded-8"
            aria-label="Verify Account"
            loading={waitAuthCheck}
            disabled={_.isEmpty(vDirtyFields) || !vIsValid}
            type="submit"
            size="large"
          >
            <span>Verify Account</span>
          </LoadingButton>
        </form>
      );
    }
    if (resetPassword) {
      return (
        <form
          name="resetForm"
          noValidate
          key={2}
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={resetFormHandleSubmit(onResetSubmit)}
        >
          <Controller
            name="password"
            control={resetFormControl}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="New Password"
                type="password"
                error={!!resetFormErrors.password}
                helperText={resetFormErrors?.password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={resetFormControl}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Confirm Password"
                type="password"
                error={!!resetFormErrors.confirmPassword}
                helperText={resetFormErrors?.confirmPassword?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Turnstile
            className="mt-8 w-full"
            theme={theme.palette.mode || 'light'}
            sitekey={settingsConfig.turnstileWidgetsKey}
            onVerify={(t) => {
              setToken(t);
              console.log('token', t);
            }}
            onExpire={() => {
              setToken(null);
              turnstile.reset();
            }}
            size="flexible"
          />

          <LoadingButton
            variant="contained"
            color="secondary"
            className=" w-full mt-16 rounded-8"
            aria-label="Reset Password"
            loading={waitAuthCheck}
            disabled={_.isEmpty(resetFormDirtyFields) || !resetFormIsValid}
            type="submit"
            size="large"
          >
            <span>Reset Password</span>
          </LoadingButton>
        </form>
      );
    }
    if (showForgotPassword) {
      return (
        <form
          name="resetForm"
          noValidate
          key={2}
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={resetFormHandleSubmit(onResetSubmit)}
        >
          {useMobielreset ? (
            <Controller
              name="mobile"
              control={resetFormControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Mobile"
                  autoFocus
                  type="mobile"
                  error={!!errors.mobile}
                  helperText={errors?.mobile?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          ) : (
            <Controller
              name="email"
              control={resetFormControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}

          <Turnstile
            className="mt-8 w-full"
            theme={theme.palette.mode || 'light'}
            sitekey={settingsConfig.turnstileWidgetsKey}
            onVerify={(t) => {
              setToken(t);
              console.log('token', t);
            }}
            onExpire={() => {
              setToken(null);
              turnstile.reset();
            }}
            size="flexible"
          />

          <LoadingButton
            variant="contained"
            color="secondary"
            className=" w-full mt-16 rounded-8"
            aria-label="Reset Password"
            loading={waitAuthCheck || processingReset}
            disabled={_.isEmpty(resetFormDirtyFields) || !resetFormIsValid || !token}
            type="submit"
            size="large"
          >
            <span>Request Reset Link</span>
          </LoadingButton>

          <Button
            variant="outlined"
            color="secondary"
            className=" w-full mt-16"
            onClick={() => {
              resetFormSetValue('email', '');
              resetFormSetValue('mobile', '');
              setUseMobileReset(!useMobielreset);
            }}
            size="large"
          >
            <span>{useMobielreset ? 'Use Email' : 'use Mobile'}</span>
          </Button>
        </form>
      );
    }
    if (showGuest) {
      return (
        <div className="mt-32">
          <FormProvider {...guestForm}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="customer_first_name"
                  control={guestForm.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      error={!!guestForm.formState.errors.customer_first_name}
                      helperText={guestForm?.formState?.errors?.customer_first_name?.message}
                      variant="outlined"
                      fullWidth
                      onChange={(ev) => field.onChange(ev.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="customer_last_name"
                  control={guestForm.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      error={!!guestForm.formState.errors.customer_last_name}
                      helperText={guestForm?.formState?.errors?.customer_last_name?.message}
                      variant="outlined"
                      fullWidth
                      onChange={(ev) => field.onChange(ev.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="customer_mobile"
                  control={guestForm.control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      label="Mobile"
                      placeholder="Mobile"
                      variant="outlined"
                      fullWidth
                      error={!!mErrors.mobile}
                      helperText={mErrors?.mobile?.message}
                      onChange={(ev) => field.onChange(ev.target.value)}
                      InputProps={{
                        startAdornment: (
                          <Controller
                            control={guestForm.control}
                            name="country_code"
                            render={({ field: _field }) => (
                              <InputAdornment position="start">
                                <CountryCodeSelectorFlag
                                  selectedCountries={selectedCountries}
                                  {..._field}
                                  onChange={(v) => _field.onChange(v)}
                                />
                              </InputAdornment>
                            )}
                          />
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="customer_email"
                  control={guestForm.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      error={!!guestForm.formState.errors.customer_email}
                      helperText={guestForm?.formState?.errors?.customer_email?.message}
                      variant="outlined"
                      fullWidth
                      onChange={(ev) => field.onChange(ev.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={guestForm.control}
                  name="acceptTermsConditions"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(ev, ch) => field.onChange(ch)}
                        />
                      }
                      label={
                        <Typography>
                          By creating an account, you agree to our{' '}
                          <Link to="/terms" target="_blank">
                            Terms & Conditions
                          </Link>
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>

              <Turnstile
                className="mt-8 w-full"
                theme={theme.palette.mode || 'light'}
                sitekey={settingsConfig.turnstileWidgetsKey}
                onVerify={(t) => {
                  setToken(t);
                  console.log('token', t);
                }}
                onExpire={() => {
                  setToken(null);
                  turnstile.reset();
                }}
                size="flexible"
              />

              <Grid item xs={12}>
                <Typography
                  className="text-center font-semibold text-16 cursor-pointer"
                  color={(th) => th.palette.secondary.main}
                  onClick={() => setShowGuest(false)}
                >
                  Already have an account? Login.
                </Typography>
              </Grid>
            </Grid>
            <LoadingButton
              variant="contained"
              color="secondary"
              className="w-full mt-16 rounded-8"
              loading={waitAuthCheck}
              disabled={
                _.isEmpty(guestForm.formState.dirtyFields) || !guestForm.formState.isValid || !token
              }
              onClick={guestForm.handleSubmit(onSubmitGuest)}
              size="large"
            >
              <span>Checkout as Guest</span>
            </LoadingButton>
          </FormProvider>
        </div>
      );
    }
    return (
      <TabContext value={tabValue} className="flex flex-col mt-24">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(ev, val) => setTabValue(val)}>
            <Tab label="Email Address" value="1" />
            <Tab label="Phone Number" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" className="p-0">
          <form
            name="emailLoginForm"
            noValidate
            key={1}
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-8"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Turnstile
              className="mt-8 w-full"
              theme={theme.palette.mode || 'light'}
              sitekey={settingsConfig.turnstileWidgetsKey}
              onVerify={(t) => {
                setToken(t);
                console.log('token', t);
              }}
              onExpire={() => {
                setToken(null);
                turnstile.reset();
              }}
              size="flexible"
            />

            <div className="flex items-baseline justify-end mt-8 mb-24">
              <Typography
                onClick={() => setShowForgotPassword(true)}
                color="secondary"
                className="cursor-pointer"
              >
                Forgot Password?
              </Typography>
            </div>

            <LoadingButton
              variant="contained"
              color="secondary"
              className=" w-full mt-16 rounded-8"
              aria-label="Sign in"
              loading={waitAuthCheck}
              disabled={_.isEmpty(dirtyFields) || !isValid || !token}
              type="submit"
              size="large"
            >
              <span>Sign in</span>
            </LoadingButton>
            {allowGuestSignIn && (
              <>
                <Typography className="my-16 text-center">OR</Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={() => setShowGuest(true)}
                  disabled={waitAuthCheck}
                >
                  Continue as Guest
                </Button>
              </>
            )}
          </form>
        </TabPanel>
        <TabPanel value="2" className="p-0">
          <form
            name="mobileLoginForm"
            noValidate
            key={3}
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={mHandleSubmit(onMSubmit)}
          >
            <Controller
              name="mobile"
              control={mControl}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  label="Mobile"
                  placeholder="Mobile"
                  variant="outlined"
                  className="mb-24 pl-10"
                  fullWidth
                  error={!!mErrors.mobile}
                  helperText={mErrors?.mobile?.message}
                  InputProps={{
                    startAdornment: (
                      <Controller
                        control={mControl}
                        name="country_code"
                        render={({ field: _field }) => (
                          <InputAdornment position="start">
                            <CountryCodeSelectorFlag
                              selectedCountries={selectedCountries}
                              {..._field}
                            />
                          </InputAdornment>
                        )}
                      />
                    ),
                  }}
                />
              )}
            />

            <Controller
              name="password"
              control={mControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!mErrors.password}
                  helperText={mErrors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Turnstile
              className="mt-8 w-full"
              theme={theme.palette.mode || 'light'}
              sitekey={settingsConfig.turnstileWidgetsKey}
              onVerify={(t) => {
                setToken(t);
                console.log('token', t);
              }}
              onExpire={() => {
                setToken(null);
                turnstile.reset();
              }}
              size="flexible"
            />

            <div className="flex items-baseline justify-end mt-8 mb-24">
              <Typography
                onClick={() => setShowForgotPassword(true)}
                color="secondary"
                className="cursor-pointer"
              >
                Forgot Password?
              </Typography>
            </div>

            <LoadingButton
              variant="contained"
              color="secondary"
              className=" w-full mt-16 rounded-8"
              aria-label="Sign in"
              loading={waitAuthCheck}
              disabled={_.isEmpty(mDirtyFields) || !mIsValid || !token}
              type="submit"
              size="large"
            >
              <span>Sign in</span>
            </LoadingButton>
          </form>
        </TabPanel>
      </TabContext>
    );
  }

  function resetPageState() {
    setShowForgotPassword(false);
    setShowVerification(false);
    setResetPassword(false);
    setVerifyEmail(false);
    setVerifyMobile(false);
    setForgotEmail(false);
    setForgotMobile(false);
  }

  function handleSignUpLinkClick() {
    if (onSignUpLinkClickCallback) {
      onSignUpLinkClickCallback();
    } else {
      navigate('/sign-up');
    }
  }

  function getFormFooter() {
    if (showVerification || showForgotPassword || resetPassword) {
      return (
        <div className="flex items-baseline mt-16 font-medium">
          <Typography>
            <EnkSvgIcon size={12} className="inline">
              heroicons-outline:arrow-left
            </EnkSvgIcon>{' '}
            Back to
          </Typography>
          <Typography className="ml-4 cursor-pointer" color="secondary" onClick={resetPageState}>
            Sign In?
          </Typography>
        </div>
      );
    }
    return (
      <div className="flex items-baseline mt-16 font-medium">
        <Typography>Don't have an account?</Typography>
        <Button
          size="small"
          color="secondary"
          disableElevation
          className="ml-4"
          onClick={handleSignUpLinkClick}
        >
          Sign up here.
        </Button>
      </div>
    );
  }

  return (
    <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0" {...props}>
      {theme.palette.mode === 'dark' ? (
        <img src="assets/images/logo/logo-text-on-dark.svg" alt="logo" width={180} />
      ) : (
        <img src="assets/images/logo/logo-text.svg" alt="logo" width={180} />
      )}
      {getFormTitle()}
      {getFormContent()}
      {getFormFooter()}
    </div>
  );
}

export default SignInForm;
