import EnkUtils from '@enk/utils';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';

import ProfileConfig from '../main/profile/ProfileConfig';
import LandingConfig from '../main/landing-pages/LandingConfig';
import AboutUsConfig from '../main/about/AboutUsConfig';
import TermsConfig from '../main/terms/TermsConfig';
import PrivacyConfig from '../main/privacy-policy/PrivacyConfig';
import FAQConfig from '../main/faq/FAQConfig';
import SpaRegistrationConfig from '../main/spa-registration/SpaRegistrationConfig';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import CustomerConfig from '../main/Customer/CustomerConfig';

const routeConfigs = [
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  ProfileConfig,
  LandingConfig,
  AboutUsConfig,
  TermsConfig,
  PrivacyConfig,
  FAQConfig,
  SpaRegistrationConfig,
  ResetPasswordConfig,
  CustomerConfig,
];

const routes = [
  ...EnkUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '404',
    element: <Error404Page />,
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
          leftSidePanel: {
            display: false,
          },
          rightSidePanel: {
            display: false,
          },
        },
      },
    },
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
